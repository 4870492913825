.multiple-table-column-list-parent-container{
    height:85vh;
}

.multiple-table-column-list-title{
    margin: 0;
    padding: 5px;
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 0.5px;
    color: rgba(12,80,163,255);
}

.multiple-table-column-list-table-title, .multiple-table-column-list-table-title-close-state{
    font-size: 15px;
    font-weight: 550;
    font-family: 'Nunito';
    padding:15px 5px 15px 8px;
    margin: 0;
    letter-spacing: 0.5px;
}

.multiple-table-column-list-table-parent-container{
    margin: 10px auto 0 auto;
    height:calc(79vh - 55px);
    box-sizing: border-box;
    overflow-y: auto;
}

.multiple-table-column-list-table-inner-parent-container{
    margin-bottom: 5px;
}

.multiple-table-column-list-table-title{
    border: 1px solid #d3d3d3;
    border-bottom: 0;
    cursor: pointer;
}

.multiple-table-column-list-table-title-close-state{
    border: 1px solid #d3d3d3;
    cursor: pointer;
    border-radius: 2px;
    margin-bottom: 2px;
}

.multiple-table-column-list-table-tr{
    border-collapse: collapse;
}
.multiple-table-column-list-table-th,.multiple-table-column-list-table-td{
    border: 1px solid #d3d3d3;
    text-align: left;
    padding: 10px 7px 10px 7px;
}

.multiple-table-column-list-table-width-100{
    width: 100%;
}

.multiple-table-column-list-table-button-container{
    height: 6vh;
    margin-top: 10px;
    margin-bottom: 0;
    display: flex;
    justify-content: end;
}

.multiple-table-column-list-table-buttons{
    width: 130px;
    height: 100%;   
    font-family: 'Nunito';
    font-size: 16px;
}
.multiple-table-column-list-table-buttons.cancelButton{
    margin-right: 20px;
}

