.PipeLine_EditForm_Parent_Contaiiner{
    height: auto;
    width:100%;
}
.Pipeline_Add_Button , .Pipeline_Back_Button{
    width: 80px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10px;
    font-size: 15px;
    font-family: 'Nunito';
}

.Pipeline_Add_Search_Input{
    height: 40px;
    width: 100%;
    font-family: 'Nunito';
}
.Pipeline_Add_Search_Sugg_Box{
    border: 1px dotted #2424;
    border-spacing: 10px;
    min-height: auto ;
    max-height:300px;
    margin: 20px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
    font-family: 'Nunito';
}
.Pipeline_Add_Search_Sugg_Inner_Box{
    border: 1px solid green;
    height: 50px;
    width: 100%;
}

.Pipeline_Search_List_Title_text{
    margin: 10px 0 0 25px;
}
.Status_Active_Text , .Status_InActive_Text{
    font-family: 'Nunito';
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0 0 0 25px;
}
.Status_Active_Text{
    color: rgba(139,195,74,1);
}
.Status_InActive_Text{
    color: rgba(233,30,99,1);
}
.Pipeline_Search_List:active{
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}