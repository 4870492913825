.post-validation-header-parent-container{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Nunito';
}

.post-validation-header-title{
    font-size: 20px;
    font-weight: 700;
    color: #98A2B3;
    margin: 0px;
    padding: 0px;
    width: fit-content;
    height: fit-content;
}

.post-validation-header-action-container{
    height: 100%;
    width: max-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.post-validation-header-action-title, .post-validation-header-action-title-count{
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
.post-validation-header-action-title{
    margin: 0 0  0 30px;
}

.post-validation-header-action-title-count{
    margin-left: 5px;
    color:#475467;
}

.post-validation-header-action-icons{
    margin-right: 5px;
}


.inset-green{
    color: #46B45E;
}
.inset-red{
    color: #FF6E6E;
}

.inset-blue{
    color: #0E34BB;
}