.th1{
    background-color: #0C50A3;
    color: #fff;

  }
  
  .td1, .th1 {
    border: 1px solid #49484876;
    /* text-align: center; */
    padding: 10px;
    height: fit-content;
  }
  .table1 {
    font-family: 'Nunito';
    border-collapse: collapse;
    width: 580px;
    margin: 0% auto;
   margin-top: 30px;
   border: 1px solid #49484876;
    
   
  }
  .in {
   
    /* outline: none; */
    /* width: 350px; 
    height: 40px;
    text-align: center;
    margin-top: 20px; */
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 14px;
    border: none;
    /* text-align: center; */
    padding-left: 3%;
    text-transform: capitalize;
    

    
}
.table-row .table-data {
    border: 1px solid #ddd;
    height: 20px;
    /* text-align: center; */
    font-weight: 700;
    padding-left: 3%;
    font-size: 15px;
  } 
  .data .table-row{
    border: 1px solid #ddd;
    
  }
  /* .table-btn{
    float: right;
    top: 30px;
    width: 20%;
  } */

  .table-btn{
    width: 90px;
    display: flex;
    justify-content: flex-end;
    right: 10px;
    position: fixed!important;
    bottom: 10px;
  }
  .newvisual-btn-cont {
    width: 590px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
  }
  .Visualimg-Container {
    max-width: 700px;
    height: fit-content;
    overflow-x: auto;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 15px;
    /* font-family: 'Nuntio'; */

}
.newconfigdtl-create-btn{
  display: inline-block;
}
.reportCreate_overall{
  height: auto;
  width: 100%;
}
.report_Add_Search_Input{
  height: 40px;
  width: 100%;
}
.report_Add_Search_Sugg_Box{
  border: 1px dotted #2424;
  border-spacing: 10px;
  min-height: auto;
  max-height: 300px;
  margin: 20px 0 0 0;
  overflow-y: scroll;
  overflow-x: hidden;
}
.report_Search_List:active{
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}
.report_Search_List_Title_text{
  margin: 10px 0 0 25px;
}
.Status_Active_Text,
.Status_InActive_Text {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0 0 0 25px;
}
.Status_Active_Text {
    color: rgb(143, 227, 47);
}

.Status_InActive_Text {
    color: rgba(233, 30, 99, 1);
}
.report-Input{
  width: 100%;
  height: 35px;
}
.report_Add_Button,
.report_Back_Button{
  width: 70px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 10px;
  font-size: 15px;
  font-family: 'Nunito';
}