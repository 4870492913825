.payment_div {
    width: 100%;
    height: 99.8vh;
    user-select: none;
    overflow: hidden;
    background-color: #e2f5f4a6;
}


.pay_logo {
    /* border: 1px solid red; */
    margin-top: 2vmax;
    margin-left: 2vmax;
    width: 20%;
   
}

.payment_card {
    /* border: 1px solid red; */
    margin: 0% auto;
    margin-top: 2vmax;
    /* margin-left: 2vmax; */
    background-color: #0c50a3;
    color: #fff;
    border-radius: 50px;
}

.payment_box {
    /* border: 1px solid red; */
    display: grid;
    gap: 2.5rem;
    margin-top: 1.5vmax;
    margin-left: 2vmax;
    grid-template-columns: 25% 25% 25%;
    font-family: 'Nunito';
}

.pay_cost {
    /* border:1px solid red; */
    width: 75%;
    margin: 0% auto;
    margin-top: 2.5%;
    display: flex;
    justify-content: space-between;
    height: 50px;
}

.payment_Head {
    color: #fff;
    font-family: 'Nunito';
    font-size: 25px;
    display: flex;
    font-weight: 600;
    position: relative;
    gap: .3rem;
    animation-name: example;
    animation-duration: 4s;
    animation-delay: 0.3s;
}

.pay_Datahud_logo{
    /* border: 1px solid red; */
    height: 45px;
    width: 75px;
    margin-right: 2.5vmax;
    /* color: red; */
}
.pay_sub_text {
    font-size: 14px;
    font-weight: 600;
    font-family: 'Nunito';
    color: orange;
    margin-top: -10px;
    list-style: none;
    position: relative;

}

.pay_sub_text::after {
    content: "";
    position: absolute;
    left: 0;
    height: 100%;
    width: 50%;
    background: #0c50a3;
    border-left: 2px solid orange;
    animation: typing 5s steps(10) infinite;
    /* border:1px solid red;  */

}

.pay_cost_text {
    font-size: 22px;
    font-family: 'Nunito';
    font-weight: 600;

}

.pay_btn {
    background-color: #0c50a3;
    color: orange;
    font-family: 'Nunito';
    height: 35px;
    font-weight: 500;
    border-radius: 5px;
}

.pay_price_text {
    /* border: 1px solid red; */
    font-size: 10px;
    font-weight: 400;
    display: flex;
    gap: .2rem;
    font-family: 'Nunito';
    cursor: pointer;
    margin-left: .3vmax;
    margin-top: .3vmax;
    animation: anime 1s infinite ease-in-out alternate;
    -webkit-animation: anime 1s infinite ease-in-out alternate;
}
.pay_price_text:hover{
    color: orange;
}

.pay_icon {
    /* border: 1px solid red; */

    width: 22px;
    margin-top: .2vmax
}

@keyframes typing {
    100% {
        left: 31.5%;

    }
}

@media screen and (max-width: 1110px) {
    .payment_div{
        /* border: 1px solid red; */
        overflow-y: auto;
        
    }
    .payment_box {
        display: grid;
        grid-template-columns: 35% 50%;
        font-family: 'Nunito';
    }

    .pay_cost {
        /* border: 1px solid red; */
        display: flex;
        font-family: 'Nunito';
    }
    .pay_sub_text::after{
        height: 100%;
        width: 53%;
       
        /* border: 1px solid red; */
    }
}
@media screen and (max-width:907px){
    .payment_box {
        display: flex;
        flex-wrap: wrap;
        /* grid-template-columns: 80%; */
        
        /* border: 1px solid red; */
        /* width: 10%; */
        justify-content: center;
        font-family: 'Nunito';
    }
    .pay_cost {
       /* border: 1px solid red; */
        display: flex;
        width: 100%;
        font-family: 'Nunito';
    }
}
@media screen and (max-width:507px){

.pay_cost_text{
    font-size: 14px;
}
}


@keyframes example {
    0% {
        left: 600px;
        top: 0px;
    }

    25% {
        left: 0px;
        top: 0px;
    }

}
@keyframes anime {
   100% {
       transform: translateX(10px);
       -webkit-transform: translateX(10px);
       -moz-transform: translateX(10px);
       -ms-transform: translateX(10px);
       -o-transform: translateX(10px);
    }
}