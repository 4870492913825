.splHead {
    /* border: 1px solid red; */

    display: flex;

}

.SplBackBtn {
    /* border: 1px solid red; */
    width: 50px;
    height: 40px;
    margin-top: 1.5vmax;

}

.SplBtn {
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: #0c50a3;
    border-radius: 50px;
    color: #fff;
    transition: 0.3s;

}

.SplBtn:hover {
    border: 1px solid #0c50a3;
    color: #0c50a3;
    background-color: #fff;
}

.splTitle {
    width: auto;
    font-size: 18px;
    margin-top: .6vmax;
    font-family: 'Nunito';
    font-weight: bold;
    height: 54px;
    /* padding: 1rem; */
    padding-top: 1rem;
    color: #1D2939;
    border: 2px solid transparent;
    background-color: #fafafa;
    /* border: 1px solid red; */
}

.splOverallContent {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 3rem;
    padding-right: 1rem;
}

.splLeftContainer {
    width: 55%;
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: auto;
    /* padding: 10px; */
    align-items: center;
}

.splLeftTitle {
    color: #0c50a3;
}

.grid-container {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    padding: 1px;
   
}

.grid-item {
    /* border: 1px solid red; */
    border: 1px solid rgba(209, 210, 212, 0.355);
    padding: 7px;
    font-size: 25px;
    color: #626262bc;
    text-align: center;
    
}

.splRighttContainer {
    width: 40%;
    /* border: 1px solid red; */
    padding-left: 1rem;
}

.splRightTitle {
    /* border: 1px solid red; */
    color: #0c50a3;
    margin-bottom: 4vmax;
}