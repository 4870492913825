.config_EditForm_Parent_Container{
    height: auto;
    width:100%;
    /* border: 2px solid red; */
}
/* .config_Update_Button{
    border: 2px solid red; 
   font-family: 'Nunito';
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 35px;
    margin: 20px 0 0 0;
} */
.config_UpdateAdd_Button {
  
font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 35px;
    margin: 20px 0 0 0;
    font-family: 'Nunito';
 }
 .ConfigEdit_box{
    /* border: 1px solid red; */
    width: 100%;
    /* display: flex;
    justify-content: space-between; */
 
 }

 .Tenant-Edit-Form-Next-Button{
    height: 35px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    color: #fff;
    /* border-radius: 50px; */
    margin-top: 20px;
}

.Tenant-Edit-Form-Back-Button{
    height: 35px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-family: 'Nunito';
    color: #fff;
    /* border-radius: 50px; */
    margin-top: 20px;
    background: rgba(12,80,163,255);
    border: 1px solid  rgba(12,80,163,255);
}