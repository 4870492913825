.Settingheader {
    width: 100%;
    font-size: 18px;
    font-family: 'Nunito';
    font-weight: bold;
    height: 64px;
    padding: 1rem;
    color: #1D2939;
    border: 2px solid transparent;
    background-color: #fafafa;
}

.Settingoption {
    /* border: 1px solid red; */
    font-weight: bold;
    grid-template-columns: 100%;
    grid-column-gap: 2em;
    grid-row-gap: 2rem;
    width: 98%;

    display: flex;
flex-wrap: wrap;
/* margin-top: 1rem; */
margin-left: 2rem;
gap: 2rem;
    /* flex-wrap: wrap; */
}

.setting_overall {
    /* border: 1px solid #C9C9C9; */
/* border-radius: 5px; */
/* box-shadow: 0 6px 7px 3px rgba(0,0,0.2); */
/* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); */
    /* border-radius: 8px; */
    width: 4%;
    /* cursor: pointer; */
    height: 40px;
    /* transition: all 0.3s linear; */
    /* perspective: 1000px; */
    /* box-shadow: 0px 6px 9px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05); */

}


.setting_overall:first-child{
border-bottom: 2.5px solid #0e109c ;
}

    .setting_overall > span {
        font-size: 20px;
        font-weight: 600;
}
.setting_overall-inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.setting_overall > p{
    font-size: 18px;

}
.roledetailImg {
    /* border: 1px solid red; */
    margin-left: 10px;
    margin-top: -1px;
    /* margin-top: -4vmax; */
    height: 45px;
}

.Set_Name {
    font-size: 15px;
    font-family: 'Nunito';
    /* font-weight: 700; */
    /* border: 1px solid red; */
    margin-top: 1vmax;
    margin-left: .2vmax;
}

.setting_overall:hover {
    transform: scale(1.1);
    
}

/* .setting_cardOne {
    transition: all 0.6s ease-out;
    transform: rotateX(0deg) rotateY(0deg);
    perspective: 1000px;
    transform-style: preserve-3d;
} */

.card>img {
    width: 50px;
}

.card {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    /* margin-top: -4; */
    /* border: 2px solid red; */
}

@media screen and (max-width: 768px) {
    .Settingoption {
        width: 80%;
        display: flex;
        flex-wrap: wrap;
        /* grid-row-gap: 1em; */
        margin-left: 1rem;
         gap: 1rem;
    }
}