.piplineDetailCreate_overall {
    height: auto;
    width: 100%;
}

.sqlExtractCreate_Update_Button {
    font-family: 'Poppins';
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 35px;
    margin: 1px 0 0 0;
}

.PipelineDetial_Add_Button,
.PipelineDetail_Back_Button {
    width: 80px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 10px;
    font-size: 15px;
    font-family: 'Nunito';
}

.PipelineDetail_Add_Search_Input {
    height: 40px;
    width: 100%;
}

.PipelineDetail_Add_Search_Sugg_Box {
    border: 1px dotted #2424;
    border-spacing: 10px;
    min-height: auto;
    max-height: 300px;
    margin: 20px 0 0 0;
    overflow-y: scroll;
    overflow-x: hidden;
}

.SqlExtract_Add_Search_Sugg_Inner_Box {
    height: 50px;
    width: 100%;
}

.PipelineDetail_Search_List_Title_text {
    margin: 10px 0 0 25px;
}

.Status_Active_Text,
.Status_InActive_Text {
    font-family: 'Poppins';
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0 0 0 25px;
}

.Status_Active_Text {
    color: rgb(143, 227, 47);
}

.Status_InActive_Text {
    color: rgba(233, 30, 99, 1);
}

.PipelineDetail_Search_List:active {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05));
}

.Pipeline-Det-Input {
    width: 100%;
    height: 35px;

}

.flex_twocontainer {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.input_shortbox {
    width: 200px;
}

.input_shordetail {
    margin-right: 200px;
}

.SqlExtract-Form-List{
    border: 1px dotted #2424;
    margin: 0;
    min-height: fit-content;
    max-height: 150;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
}

.pipeline-details-selected-column-tags{
    padding: 2px 15px;
    border-radius: 5px;
    background-color: #d3d3d3;
    font-family: 'Nunito';
    font-weight: 500;
    letter-spacing: 0.5px;
}