.table-list-parent-container-source{
    border-radius: 16px;
    background: #f3f3ff;
    padding: 40px 0;
    min-height: calc(100vh - 200px);
}

.table-list-parent-container-target{
    border-radius: 16px;
    background: #ecf9ff;
    padding: 40px 0;
    min-height: calc(100vh - 200px);
}

.table-list-title, table-list-title-count{
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
}
.table-list-title{
    margin: 0 0  0 30px;
}

.table-list-title-count{
    margin-left: 5px;
    color:#101828;
}

.table-list-title-icons{
    margin-right: 5px;
}


.table-list-title-source{
    color:#1E06AD;
}

.table-list-title-target{
    color: #1983B1;
}

.table-list-table-container{
    border-collapse: collapse;
    width: 100%;
    margin: 20px 0 0 30px;
}

.table-list-table-th, .table-list-table-td{
    padding: 10px;
    text-align: left;
}

.table-list-td-title{
    padding: 0;
    margin: 0;
}

.th-source{
    color: #1E06AD;
}

.th-target{
    color: #1983B1;
}


.td-target-title{
    color: #82BED8;
}

.td-source-title{
    color:#897DD6;
}

.table-list-td-content{
    font-size: 12px;
    font-weight: 400;
    color: #667085;
    margin: 0;
    padding: 0;
}

.table-list-td-content-value{
    color:  #1D2939;
    margin: 0 0 0 10px;
    font-weight: 700;
}