.column-listing-table-parent-container{
    height: max-content;
}

.column-listing-table-title{
    margin: 0;
    padding: 5px;
    font-family: 'Nunito';
    font-size: 20px;
    font-weight: 550;
    letter-spacing: 0.5px;
}

.column-listing-table-search{
    margin: 20px auto;
}

.column-listing-table-container{
    height: 57vh;
    overflow-y: auto;
}

.column-listing-table-tr{
    border-collapse: collapse;
}
.column-listing-table-th,.column-listing-table-td{
    border: 1px solid #d3d3d3;
    text-align: left;
    padding: 10px 7px 10px 7px;
}

.column-listing-table-width-100{
    width: 100%;
}
.column-listing-table-button-container{
    height: 6vh;
    margin-top: 10px;
    margin-bottom: 0;
    display: flex;
    justify-content: end;
}

.column-listing-table-buttons{
    width: 130px;
    height: 100%;   
    font-family: 'Nunito';
    font-size: 16px;
}
.column-listing-table-buttons.cancelButton{
    margin-right: 20px;
}  
