.input-field-error-message {
    font-family: 'Nunito';
    letter-spacing: 0.5px;
    color: red;
}

.default-error-field {
    margin: 5px 0 5px 0;
}

.field-error {
    margin: -20px 0 0 0;
}