.Schema_EditForm_Parent_Container{
    height: auto;
    width:100%;
    /* border: 2px solid red; */
}
/* .config_Update_Button{
    border: 2px solid red; 
   font-family: 'Nunito';
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    height: 35px;
    margin: 20px 0 0 0;
} */
.Schema_Update_Button {
  
    width: 500px!important;
    display: flex;
    justify-content: flex-end;
    /* border: 2px solid red;  */
    position: fixed;
    bottom: 10px;
 }
 