.NewRole-Container{
    height: auto;
    width:100%;
    
}
    /* .newroleAdd-create-btn{
        font-family: 'Nunito';
        font-size: 13px;
        font-weight: 500;
        width: 100%;
        height: 35px;
        border: solid 2px red;
        margin: 20px 0 80px 0;
        border-radius: 5px;
        letter-spacing: 0.5px;
} */

.pageBorder{
    height: 250px;
    width: 600px;
    border: solid 1px  rgba(0, 0, 0, 0.203);
    overflow-y: scroll;
    overflow-x: hidden ;
    

}
.NewRole-Button-Container{
    /* border: 1px solid red; */
    width: 550px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    font-size: 16px;
}