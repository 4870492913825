.pre-validation-success-message-aparent-container{
    width: 100%;
    height: max-content;
}

.pre-validation-success-message-icon{
    width: 200px;
    display: block;
    margin: 50px auto 30px;
}

.pre-validation-success-message-text{
    font-size: 18px;
    font-family: 'Nunito';
    text-align: center;
    letter-spacing: 0.5px;
    font-weight: 400;
}

.pre-validation-success-message-button-container{
    display: flex;
    margin-top: 60px;
}

.pre-validation-success-message-button{
    border: 2px solid rgb(12, 80, 163,1);
    margin: 0 auto 0;
    width: 200px;
    height: 40px;
    font-family: 'Nunito';
    font-size: 15px;
    letter-spacing: 0.5px;
    font-weight: 500;
    color:rgb(12, 80, 163,1);
}

.pre-validation-success-message-button:hover{
    color: #fff;
    background: rgb(12, 80, 163,1);
}