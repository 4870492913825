.User-Profile-Parent-Container {
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    background-color: #fff;
    user-select: none;
}

.User-Profile-Header-Container {
    border: 1px solid transparent;
    height: 30px;
    width: 100%;
    margin: 0 0 0 20px;
    display: flex;
    justify-content: space-between;
}

.User-Profile-Header-Text {
    font-family: 'Nunito';
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: rgba(25, 25, 25, 1);
    user-select: none;
}

.User-Profile-Edit-Enable-Button{
    border: 1px solid transparent;
    margin: 0 50px 0 0;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    cursor: pointer;
}

.User-Profile-Edit-Enable-Button:active{
    background-color: rgba(234,234,234,1);
}

.User-Profile-Edit-Enable-Icon{
    margin: 5px 0 0 5px;
    color:  rgba(12,80,163,255);
}

.User-Profile-Body-Container {
    border: 1px solid transparent;
    width: 95%;
    height: calc(100% - 50px);
    margin: 5px auto 0 auto;
}

.User-Profile-Main-Container-One {
    border: 1.5px solid transparent;
    width:800px;
    height: calc(92vh - 50px);
    margin: 10px auto 0 auto;
    border-radius: 10px;
}

.User-Profile-Avatar-Container {
    border:none;
    border-top:1.5px solid #f1f1f1 ;
    border-left: 1.5px solid #f1f1f1;
    border-right: 1.5px solid #f1f1f1;
    width: 100%;
    height: 200px;
    border-radius: 10px 10px 0px 0;
}
.User-Profile-Avatar-Bg{
    border: 1px solid transparent;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 0px 0;
    background-image:  linear-gradient(30deg, #0c50a3 12%, transparent 12.5%, transparent 87%, #0c50a3 87.5%, #0c50a3), linear-gradient(150deg, #0c50a3 12%, transparent 12.5%, transparent 87%, #0c50a3 87.5%, #0c50a3), linear-gradient(30deg, #0c50a3 12%, transparent 12.5%, transparent 87%, #0c50a3 87.5%, #0c50a3), linear-gradient(150deg, #0c50a3 12%, transparent 12.5%, transparent 87%, #0c50a3 87.5%, #0c50a3), linear-gradient(60deg, rgba(12,80,163,0.5) 25%, transparent 25.5%, transparent 75%, rgba(12,80,163,0.5) 75%, rgba(12,80,163,0.5)), linear-gradient(60deg, rgba(12,80,163,0.5) 25%, transparent 25.5%, transparent 75%, rgba(12,80,163,0.5) 75%, rgba(12,80,163,0.5));
    background-size: 64px 112px;
    background-position: 0 0, 0 0, 32px 56px, 32px 56px, 0 0, 32px 56px;
    background-color: #03a9f4;

}
.User-Profile-Avatar{
    position: fixed;
    margin:120px 0px 0 336px;
}
.User-Profile-Detail-Container{
    border: 1.5px solid #f1f1f1;
    height: calc(100% - 200px);
    border-radius: 0 0 10px 10px;
    width: 100%;
    padding: 0 10px 0 10px;
}
.User-Profille-Name-Tag{
    border: 1px solid transparent;
    margin: 43px 0 0 0;
    text-align: center;
    font-family: 'Nunito';
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: rgba(12,80,163,255);
}

.User-Profile-Form-Container{
    border: 1px solid transparent;
    height:calc(100% - 90px);
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 13px 0 0 0;

}

.User-Profile-Form-Item{
    font-family: 'Nunito';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #000;
}

.User-Form-Input-Field{
    border: none;
    border-bottom: 1px solid #f1f1f1;
    font-family: 'Nunito';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color:  rgba(12,80,163,255) !important;
    border-radius: 5px;
}
.User-Profile-Update-Button{
    width: 100%;
    margin: 25px 0 10px 0;
    border-radius: 5px;
    height: 35px;
    font-family: 'Nunito';
    font-weight: 600;
    letter-spacing: 0.5px;
}

