.Reset-Password-Parent-Container{
    border: 1px solid transparent;
    height: 100%;
    width: 100%;
}
.Reset-Password-Header-Text{
    font-family: 'Nunito';
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 700;
    color: rgba(25, 25, 25, 1);
    user-select: none;
}
.Reset-Password-Main-Container{
    border: 1px solid #f1f1f1;
    width: 400px;
    height: fit-content;
    margin: 20vh auto 0 auto;
    padding: 10px;
    border-radius: 10px;
    background-color: #f1f1f1;
}

.Reset-Password-Input{
    height: 35px;
    width: 100%;
    border-radius: 5px;
}

.Reset-Password-Button-One{
    height: 35px;
    width: 100%;
    border-radius: 5px;
    font-family: 'Nunito';
    letter-spacing: 0.5px;
    font-size: 15px;
    font-weight: 500;
    margin: 0 0 10px 0;
}
.Reset-Password-Rule-Text{
    border: 1px solid transparent;
    width: 100%;
    font-size: 9px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color:rgba(12,80,163,255);
    margin: 0 0 10px 0;
    text-align: center;
}