.dType_view_overall {
    background-color: #d6d2d208;
    border: 1px solid rgba(218, 214, 214, 0.801);
    width: 415px;
    min-height: fit-content;
    max-height: 250px;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    padding: 5px;
}