.PipelineDetail_EditForm_Parent_Container{
    height: auto;
    width:100%;
}
.PipelineDetail_Update_Button{
    
    width: 80px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 10px;
    font-size: 15px;
}