.ForgotPassword_Parent_Container,
.Password_Changed_Msg_Container,
.Password_Parent_Container {
    border: 1px solid transparent;
    height: auto;
    width: fit-content;
}

.ForgotPassword_Parent_Container {
    margin: 10vh auto 0 auto;
}

.ForgotPassword_Header_Text {
    font-family: 'Poppins';
    letter-spacing: 1px;
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;
    color: #000000;
}

.ForgotPassword_Form_Container {
    border: 1px solid transparent;
    margin: 20px 0 0 0;
    font-family: 'Poppins';
}

.ForgotPassword_Input {
    height: 45px;
    width: 400px;
    margin: 8px 0 0 0%;
    border-radius: 30px;
}

.ForgotPassword_Button {
    height: 45px;
    width: 400px;
    margin: 15px 0 0 0%;
    border-radius: 30px;
    font-family: 'poppins';
    letter-spacing: 1px;
}

.ForgotPassword_BottomText_Container {
    border: 1px solid transparent;
    height: 30px;
    width: fit-content;
    margin: 20px auto 0 auto;
}

.ForgotPassword_BottomText {
    display: flex;
    font-family: "Poppins";
    color: rgba(12, 80, 163, 255);
    cursor: pointer;
}

.Password_Logo_Container {
    height: 70px;
    width: 250px;
    margin: 10px 0 0 50px;
}

.Reset_Password_Header_Text {
    font-size: 30px;
    font-family: "Poppins";
    color: #000000;
    letter-spacing: 1px;
    font-weight: 600;
}

.Password_Parent_Container {
    margin: 5vh auto;
}

.Password_Changed_Msg_Container {
    margin: 20vh auto;
}

.Password_Changed_Msg {
    font-family: "Poppins";
    font-weight: 600;
    font-size: 30px;
    letter-spacing: 1px;
    color: #000000;
}